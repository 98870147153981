import { Component, OnInit } from '@angular/core';
import { ComponentLanding } from "../../../../../models/models.model";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { STORE_NAME } from "../../../../core/constants/store.constants";
import { environment } from "../../../../../environments/environment";


@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html'
})
export class EditorComponent implements OnInit {
  component: ComponentLanding;
  htmlData: SafeHtml;

  readonly STORE_NAME = STORE_NAME;
  readonly environment = environment;

  static acceptsComponent(cmp: ComponentLanding ): boolean {
    return cmp.type === 'editor';
  }

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.htmlData = this.acceptHtml(this.component.data['content']);
  }

  acceptHtml(htmlCode) {
    return this.sanitizer.bypassSecurityTrustHtml(htmlCode);
  }
}
