import { UrlService } from "../services/url.service";
import { MetaTagInterface } from "../interfaces/metatag.inteface";

import { environment } from "../environments/environment";

import { Data } from '@angular/router';

import * as lodash from 'lodash-es';
import { IContent } from "../app/new-core/shared/modals/points-errors/points-errors.component";



export class Base implements RelationModel {

  numberToCurrency(number: number) {
    return Math.round(number);
  }

  belongsTo() {
    return [];
  }

  hasMany() {
    return [];
  }

  attributes(): string[] {
    return [];
  }

  afterParse() {}
}

export class Bank extends Base {
  name: string;
  bank_id: number;
  brands: CardBrand[];
  product: Product;

  attributes() {
    return [
      'name', 'bank_id'
    ];
  }

  belongsTo() {
    return [
      { 'name': 'product', 'class': Product }
    ];
  }

  hasMany() {
    return [
      { 'name': 'brands', 'class': CardBrand }
    ];
  }
}

export class Bin extends Base {
  number: number;
  installments_availables: string[];

  attributes() {
    return ['number', 'installments_availables'];
  }
}

export class Brand extends Base {
  id: number;
  login: string;
  full_name: string;
  shop_id: number;
  profile: BrandProfile;
  variants: Variant[];

  attributes() {
    return ["id", "login", "full_name", "shop_id"];
  }

  belongsTo() {
    return [
      {'name': 'profile', 'class': BrandProfile},
    ];
  }

  hasMany() {
    return [
      { 'name': 'variants', 'class': Variant},
    ];
  }
}

export class CardBrand extends Base {
  brand: string;
  name: string;
  installments: Installment[];
  bank: Bank;
  max_installment_message: string;

  attributes() {
    return ["brand", 'name', 'max_installment_message'];
  }

  belongsTo() {
    return [
      {'name': 'bank', 'class': Bank},
    ];
  }

  hasMany() {
    return [
      { 'name': 'installments', 'class': Installment},
    ];
  }
}

export class Catalog extends Base {
  category: Category;
  manufacturer: Manufacturer;
  variants: Variant[];
  filters: FilterContainer;
  breadcrumb: BreadcrumbItem[];
  title: string;
  pagination: Pagination;
  not_found: boolean;
  catalog_name: string;

  attributes() {
    return ['title', 'not_found', 'catalog_name'];
  }

  belongsTo() {
    return [
      {'name': 'category', 'class': Category},
      {'name': 'manufacturer', 'class': Manufacturer},
      {'name': 'filters', 'class': FilterContainer},
      {'name': 'pagination', 'class': Pagination}
    ];
  }

  hasMany() {
    return [
      { 'name': 'variants', 'class': Variant},
      { 'name': 'breadcrumb', 'class': BreadcrumbItem },
    ];
  }

  getMetaDescription() {
    let nameable: string;

    if (this.category.slug) {
      nameable = this.category.slug;
    } else if (this.manufacturer.slug) {
      nameable = this.manufacturer.slug;
    } else {
      nameable = "";
    }

    return `Compra de artículos de ${nameable.split('-').join(' ')} en ${environment.name}`;
  }

  getCatalogName() {
    return this.catalog_name || this.title;
  }
}

export class Category extends Base {
  id: number;
  name: string;
  title: string;
  slug: string;
  parent: Category;
  childs: Category[] = [];
  cover: Cover;

  attributes() {
    return ['id', 'name', 'slug', 'parent', 'title'];
  }

  belongsTo() {
    return [
      { 'name': 'cover', 'class': Cover }
    ]
  }

  getUrl() {
    return ['/catalog', this.slug];
  }
}

export class CheckoutCart extends Base {
  address: Address;
  address_id: number;
  balance_due: boolean;
  subscription_newsletter: boolean;
  checkout_items: CheckoutItem[];
  choosen_delivery_options: any[];
  countries: any[];
  coupon: Coupon;
  coupon_items_discount: number;
  coupon_total_discount: number;
  customer: Customer;
  data: {};
  discount: number;
  delivery_options: any;
  delivery_option_id: any;
  gateways: Gateways;
  get_promotion: {};
  has_shipment_bonification: boolean;
  has_shipment_promotion: boolean;
  insurance: { ARS: number };
  identity_questions: boolean;
  ip: string;
  is_pickeable: null;
  landing_id: number;
  max_installments: number;
  network: string;
  payment: any;
  promotion: Promotion;
  promotion_discount: number;
  purchase_id: string;
  shipping_cost: number;
  subtotal: number;
  taxes_cost: number;
  title: string;
  total: number;
  total_points: number;
  user_points: number;

  attributes() {
    return[
      'address', 'address_id', 'balance_due', 'choosen_delivery_options', 'countries',
      'coupon', 'coupon_items_discount', 'coupon_total_discount', 'customer',
      'data', 'delivery_options', 'delivery_option_id', 'discount', 'get_promotion', 'has_shipment_bonification',
      'has_shipment_promotion', 'ip', 'is_pickeable', 'landing_id', 'max_installments',
      'network', 'payment', 'promotion', 'promotion_discont', 'purchase_id',
      'shipping_cost', 'subtotal', 'taxes_cost',  'title', 'total', 'subscription_newsletter',
      'total_points' , 'user_points', 'insurance', 'identity_questions'
    ];
  }

  belongsTo() {
    return [
      { 'name': 'gateways', 'class': Gateways },
      { 'name': 'promotion', 'class': Promotion }
    ];
  }

  hasMany() {
    return [
      { 'name': 'checkout_items', 'class': CheckoutItem }
    ];
  }

  // TODO, should it be removed? there is no implementation
  // We should do it after all tests will be written
  toForm() {
    return {
      address_id: this.address_id,
      coupon: this.coupon,
      data: this.data,
      delivery_options: this.delivery_options,
      delivery_option_id: this.delivery_option_id,
      ip: this.ip,
      network: this.network,
      purchase_id: this.purchase_id,
      landing_id: this.landing_id,
    };
  }

  printableDeliveryOptions() {
    return this.delivery_options;
  }

  noItems() {
    return this.checkout_items === undefined || this.checkout_items.length === 0
  }

  parsedDiscount() {
    return this.numberToCurrency(this.discount);
  }

  parsedShippingCost() {
    return this.numberToCurrency(this.shipping_cost);
  }

  parsedTotal() {
    return this.numberToCurrency(this.total);
  }

  subTotal() {
    return this.numberToCurrency(this.subtotal);
  }

  countItems() {
    return this.checkout_items === undefined ? 0 : this.checkout_items.length;
  }

  duesPrice(due) {
    return (this.total / parseInt(due.installments_number)).toFixed(2);
  }

  findCheckoutItemBy(variantId) {
    return this.checkout_items.find((item) => item.variant.id === Number(variantId))
  }

  updateShippingCost(cost) {
    this.shipping_cost = cost;
  }

  setDeliveryOptionIds(optionIds) {
    this.delivery_option_id = optionIds;
  }

  getDeliveryOptionIds() {
    return this.delivery_option_id;
  }

  arePoints() {
    return (this.total_points > 0);
  }

  updateCostByCustomerPoints(percentage: number) {
    this.total = this.total * (1 - (percentage/100))
  }

}

export class FilterContainer extends Base {
  brand: Filter;
  category: Filter;
  color: Filter;
  price: Filter;
  sale: Filter;
  cilindrada?: Filter;
  motorizacion?: Filter;
  tipo_de_vehiculo?: Filter;

  belongsTo() {
    return [
      { 'name': 'brand',              'class': Filter },
      { 'name': 'category',           'class': Filter },
      { 'name': 'color',              'class': Filter },
      { 'name': 'price',              'class': Filter },
      { 'name': 'sale',               'class': Filter },
      { 'name': 'cilindrada',         'class': Filter },
      { 'name': 'motorizacion',       'class': Filter },
      { 'name': 'tipo_de_vehiculo',   'class': Filter }
    ];
  }

  getFilterKeys(store) {
    if (store === 'MiMoto') return ['category', 'brand', 'sale', 'price', 'color', 'cilindrada', 'motorizacion', 'tipo_de_vehiculo'];
    if(store != 'nuuv') return ['category', 'brand', 'sale', 'price', 'color'];
    return ['category', 'brand', 'color'];
  }
}

export class FilterItem extends Base {
  name: string;
  slug: string;
  href: string;
  href_app: string;
  href_remove: string;
  active: boolean;
  childs: FilterItem[];
  count: number;
  subtitle: string;

  hasMany() {
    return [
      { name: 'childs', class: FilterItem },
    ];
  }

  attributes() {
    return ['name', 'href', 'href_app', 'href_remove', 'active', 'count', 'slug', 'subtitle'];
  }

  parseUrl() {
    return this.parseAvenidaUrl(this.href);
  }

  parseUrlApp() {
    return this.parseAvenidaUrl(this.href_app);
  }

  parseActiveUrl() {
    return UrlService.getQueryParams((this.href_remove).replace('/ar/products', '')
    .replace('products', '')
    .replace('/ar/', ''));
  }

  parseAvenidaUrl(link) {
    if (!link) {
      return null;
    }

    return (this.active === true && this.href_remove ? this.href_remove : link)
      .replace('/ar/products', '')
      .replace('products', '')
      .replace('/ar/', '');
  }

  setUrl(url: string) {
    this.href = url;
  }

  getUrl() {
    return UrlService.getUrl(
      this.href_app ? this.parseUrlApp() : this.parseUrl(),
      '/catalog'
    );
  }

  getQueryParams() {
    return UrlService.getQueryParams(
      this.href_app ? this.parseUrlApp() : this.parseUrl()
    );
  }
}

export class Filter extends Base {
  title: string;
  items: FilterItem[];
  selected_items: FilterItem[];

  hasMany() {
    return [
      { name: 'items', class: FilterItem},
      { name: 'selected_items', class: FilterItem }
    ];
  }

  attributes() {
    return ['title'];
  }
}

export class BreadcrumbItem extends FilterItem {
  name: string;
  href: string;
  active = false;
  query: boolean;

  hasMany() { return []; }
  attributes() { return ['name', 'href', 'query']; }

  getUrl() {
    const base = ['/catalog'];

    if (this.query) {
      return base;
    }

    return UrlService.getUrl(this.parseUrl(), base);
  }

  getQueryParams() {
    if (this.query) {
      return {
        query: this.name,
      }
    }

    return UrlService.getQueryParams(this.parseUrl());
  }
}

export class Gateways extends Base {
  keys: string[];
  decidir: Decidir;
  todopago: Todopago;
  todopago_decidir: TodopagoDecidir;
  mercadopago: MercadopagoDecidir;
  mercadopago_decidir: MercadopagoDecidir;
  mercadopago_ticket: MercadopagoTicket;
  decidir_no_bines: DecidirNoBines;
  tarjeta_digital: TarjetaDigital;
  firstdata: FirstData;


  attributes() {
    return ['keys'];
  }

  belongsTo() {
    return [
      { 'name': 'decidir',    'class': Decidir },
      { 'name': 'todopago',   'class': Todopago },
      { 'name': 'todopago_decidir',   'class': TodopagoDecidir },
      { 'name': 'mercadopago', 'class': MercadopagoDecidir },
      { 'name': 'mercadopago_decidir', 'class': MercadopagoDecidir },
      { 'name': 'mercadopago_ticket', 'class': MercadopagoTicket},
      { 'name': 'decidir_no_bines', 'class': DecidirNoBines},
      { 'name': 'tarjeta_digital', 'class': TarjetaDigital},
      { 'name': 'firstdata', 'class': FirstData},
    ];
  }
}

export class Installment extends Base {
  active: boolean;
  cft: number;
  tea: number;
  tna: number;
  coef: number;
  amount: number;
  base_amount: number;
  installments: number;
  installments_amount: number;

  attributes() {
    return ["active", 'amount', 'base_amount', 'cft', 'installments', 'installments_amount', 'tea', 'tna', 'coef'];
  }

  belongsTo() {
    return [
      {'name': 'card_brand', 'class': CardBrand},
    ];
  }
}

export class Landing extends Base {
  id: number;
  name: string;
  slug: string;
  active: boolean;
  home: boolean;
  meta_description: string;
  meta_title: string;
  components: ComponentLanding[];

  hasMany() {
    return[
      {name: 'components', class: ComponentLanding}
    ];
  }

  attributes() {
    return ['id', 'name', 'slug', 'active', 'home', 'meta_description', 'meta_title'];
  }
}

export class ComponentLanding extends Base {
  id: number;
  title: string;
  type: string;
  active: boolean;
  position: number;
  data: any;
  view_more_url: string;

  attributes() {
    return ['id', 'title', 'type', 'active', 'position', 'data', 'view_more_url'];
  }
}

export class Manufacturer extends Base {
  id: number;
  name: string;
  slug: string;
  logo = {normal: '', thumb: ''};

  getUrl() {
    return ['/catalog', this.slug];
  }

  attributes() {
    return ['id', 'name', 'slug', 'logo'];
  }
}

export class Menu extends Base {
  id: number;
  title: string;
  name: string;
  url: string;
  slug: string;
  order: number;
  childs: Menu[];

  hasMany() {
    return [
      {name: 'childs', class: Menu}
    ];
  }

  attributes() {
    return ['id', 'title', 'name', 'url', 'slug', 'order', 'childs'];
  }

  getName(): string {
    return this.name === undefined ? this.title : this.name;
  }

  getUrl() {
    return UrlService.getUrl(this.url);
  }

  getQueryParams() {
    return UrlService.getQueryParams(this.url);
  }

  onSale() {
    return (this.title.toUpperCase() === "OFERTAS") || (this.title.toUpperCase() === "HOT SALE");
  }

  isTravel(){
    return this.url.split('/').includes('viajes')
  }

  isDespegar(){
    return this.url.split('/').includes('despegar')
  }

  isAerolineas() {
    return this.url.split('/').includes('aerolineas-plus')
  }
}

export class Pagination extends Base {
  current_page: number;
  total_pages: number;
  pages: {isPoint: boolean, page: number, currentPage: boolean}[];

  attributes() {
    return ['current_page', 'total_pages'];
  }

  fetchPrev() {
    if (this.hasPrev()) {
      return {p: this.current_page - 1}
    }

    return null;
  }

  fetchNext() {
    if (this.hasNext()) {
      return {p: this.current_page + 1}
    }

    return null;
  }

  setPage(page) {
    return {p: page}
  }

  hasPrev() {
    return this.current_page > 1;
  }

  hasNext() {
    return this.current_page < this.total_pages;
  }

  buildPages() {
    this.pages = [];
    let i;

    if (this.current_page > 1) {
      for (i = this.current_page - 1; i > (this.current_page - 5); i--) {
        if (i >= 1) {
          this.pages.unshift({
            isPoint: false,
            page: i,
            currentPage: false,
          })
        }
      }

      if (i > 1) {

        if (i > 2) {
          this.pages.unshift({
            isPoint: true,
            page: null,
            currentPage: null,
          });
        }

        this.pages.unshift({
          isPoint: false,
          page: 1,
          currentPage: false,
        })
      }
    }

    // Show current and next 4 pages
    for (i = this.current_page; i < (this.current_page + 5); i++) {
      if (i <= this.total_pages) {
        this.pages.push({
          isPoint: false,
          page: i,
          currentPage: i === this.current_page,
        });
      }
    }

    // Check if there are remaining pages
    if (i < this.total_pages) {

      if (i < (this.total_pages - 1)) {
        this.pages.push({
          isPoint: true,
          page: null,
          currentPage: null,
        });
      }

      this.pages.push({
        isPoint: false,
        page: this.total_pages,
        currentPage: false,
      });
    }

    return this.pages;
  }
}

export class Picture extends Base {
  id: number;
  thumb: string;
  large: string;
  st: string;

  attributes() {
    return ['id', 'thumb', 'large', 'st'];
  }
}

export class Product extends Base {
  id: number;
  title: string;
  slug: string;
  available_installments: number[];
  available_properties: {}[] | any[];
  available_properties_names: {};
  breadcrumb: BreadcrumbItem[];
  currency_symbol: string;
  currency_code: string;
  data: {}[];
  data_shipment: {}[];
  description: string;
  total_stock: number;
  unavailable: boolean;
  pickeable: boolean;
  pictures: Picture[];
  category: Category;
  manufacturer: Manufacturer;
  shop: Shop;
  variants: Variant[];
  regular_price: number;
  regular_price_without_taxes?: number;
  is_on_sale: boolean;
  sale_price: number;
  sale_price_without_taxes?: number;
  percent_off: number;
  has_no_property: boolean;
  propertiable: Property[];
  recommended_products: Variant[];
  max_installments: number;
  max_installments_info: any;
  promotions: Bank[];
  purchasable: boolean;
  points: number;
  points_equivalence: number;
  min_points_price_for_variants: number;
  transaction_type: string;
  reservable: boolean;
  shop_id: number;
  shop_title: String;
  shop_public_name: string;
  full_points_offer?: FullPointsOffer | null;
  cucarda?: any;

  attributes() {
    return [
      'id',
      'title',
      'slug',
      'available_properties',
      'available_properties_names',
      'currency_symbol',
      'currency_code',
      'data',
      'data_shipment',
      'description',
      'total_stock',
      'unavailable',
      'pickeable',
      'pictures',
      'shop',
      'regular_price',
      'regular_price_without_taxes',
      'is_on_sale',
      'purchasable',
      'reservable',
      'sale_price',
      'sale_price_without_taxes',
      'percent_off',
      'has_no_property',
      'max_installments',
      'max_installments_info',
      'promotions',
      'points',
      'points_equivalence',
      'transaction_type',
      'min_points_price_for_variants',
      'available_installments',
      'shop_id',
      'shop_title',
      'shop_public_name',
      'full_points_offer',
      'cucarda'
    ];
  }

  belongsTo() {
    return [
      { 'name': 'category', 'class': Category },
      { 'name': 'manufacturer', 'class': Manufacturer },
      { 'name': 'shop', 'class': Shop }
    ];
  }

  hasMany() {
    return [
      { 'name': 'pictures', 'class': Picture },
      { 'name': 'variants', 'class': Variant },
      { 'name': 'propertiable', 'class': Property },
      { 'name': 'breadcrumb', 'class': BreadcrumbItem },
      { 'name': 'recommended_products', 'class': Variant },
      { 'name': 'promotions', 'class': Bank },
    ];
  }

  getUrl() {
    return ['/products', this.slug];
  }

  findVariantByProperties(properties: {}) {
    if (this.has_no_property) {
      return this.variants[0];
    }
    return this.variants.filter( (v: Variant) =>  lodash.isEqual(v.properties, properties) )[0];
  }

  findVariantByColorValue(value) {
    return this.variants.filter(
      (v: Variant) => {
        if(v.properties['color']) {
          return v.properties['color']['slug_name'] === value['slug_name'];
        } else {
          return value.slug_name
        }
      }
    )[0]
  }

  findPictureByVariant(variant: Variant) {
    let index = 0;
    for (const picture of this.pictures) {
      if (picture.id === variant.picture_id) {
        return index;
      }

      index++;
    }

    return null;
  }

  regularPrice() {
    return this.numberToCurrency(this.regular_price);
  }

  salePrice() {
    return this.numberToCurrency(this.sale_price);
  }

  onSalePrice() {
    return this.is_on_sale ? this.sale_price : this.regular_price;
  }

  has_stock() {
    return this.shop ? (this.total_stock > 0 && this.shop.visible) : (this.total_stock > 0);
  }

  getMetadescription() {
    if (environment.name == "Macro") {
      return `${this.title}. Con tus Tarjetas Macro tenés la mejor financiación.`;
    } else {
      return `${this.title}. Ofertas y pago en cuotas en ${environment.name}.`;
    }
  }

  findVariantByImage(imageSrc) {
    let pictureId = null;
    for (const picture of this.pictures) {
      if (imageSrc.indexOf(picture.st) >= 0) {
        pictureId = picture.id
      }
    }

    return this.variants.filter( (v: Variant) => v.picture_id === pictureId )[0];
  }
}

//PointsOffer for macro

export class FullPointsOffer {
  original_points_price: number
  discount_points_price: number | null
  percent_off_points: number | null
  is_only_full_points: boolean
}

export class ProductVariant extends Base {
  variant_id: number;
  variant: Variant;
  product: Product;

  attributes() {
    return ['variant_id'];
  }

  belongsTo() {
    return [
      { name: 'product', class: Product },
    ];
  }

  selectVariant(variant: Variant) {
    this.variant = variant;
  }

  afterParse() {
    this.selectVariant(this.product.variants.find((variant) => this.variant_id === variant.id));
  }
}

export class Property extends Base {
  key: string;
  data: {}[];

  translate = {
    color: 'Color',
    size: 'Talle',
  };

  attributes() {
    return ['key', 'data'];
  }

  name() {
    // we should check for custom properties
    return this.translate[this.key] || this.key;
  }

}

export interface RelationModel {
  belongsTo(): {name: string, class: {new(): RelationModel}}[];
  hasMany(): {name: string, class: {new(): RelationModel}}[];
  afterParse(): void;
  attributes(): string[];
}

export class Shop extends Base {
  id: number;
  title: string;
  public_name: string;
  visible: boolean;
  products: Product[];

  attributes() {
    return ['id', 'title', 'visible', 'public_name'];
  }

  hasMany() {
    return [
      { 'name': 'products', 'class': Product }
    ];
  }
}

export class Sitemap extends Base {
  id: number;
  title: string;
  name: string;
  url: string;
  slug: string;
  order: number;
  childs: Sitemap[];

  hasMany() {
    return [
      {name: 'childs', class: Sitemap}
    ];
  }

  attributes() {
    return ['id', 'title', 'name', 'url', 'slug', 'order'];
  }

  getName(): string {
    return this.name === undefined ? this.title : this.name;
  }

  getUrl() {
    return UrlService.getUrl(this.url);
  }
}

export class Store extends Base {
  id: number;
  email: string;
  facebook: string;
  has_visa_puntos: boolean;
  instagram: string;
  miles_equivalent?: number;
  points_equivalent?: number;
  name: string;
  popup_image: string;
  telephone: number;
  title: string;
  twitter: string;
  use_bines: boolean;
  visa_puntos_equivalence?: any;
  visa_puntos_sube_equivalence?: any;
  visa_puntos_recargas_equivalence?: any;
  visa_puntos_delivery_equivalence?: any;
  limit_amount?: number;

  attributes() {
    return [
      'id',
      'email',
      'facebook',
      'has_visa_puntos',
      'instagram',
      'miles_equivalent',
      'points_equivalent',
      'name',
      'popup_image',
      'telephone',
      'title',
      'twitter',
      'use_bines',
      'visa_puntos_equivalence',
      'visa_puntos_sube_equivalence',
      'visa_puntos_recargas_equivalence',
      'limit_amount'
    ]
  }
}

export class Variant extends Base {
  id: number;
  name: string;
  picture_id: number;
  picture_thumb: string;
  picture_medium: string;
  picture_medium_tm: string;
  picture_medium_l: string;
  gp_sku: string;
  ean_code: string;
  url: string;
  sku: string;
  properties: {
    color?: {
      hex?: string
      name?: string
      slug_name?: string
    },
    size?: string
  };
  promotions: Bank[];
  product: Product; // we should link it someway
  quantity: number;
  points: number;
  points_price: number;
  discount_top: number;

  attributes() {
    return [
      'id', 'name', 'picture_id', 'picture_thumb', 'picture_medium',
      'picture_medium_tm','picture_medium_l', 'gp_sku', 'ean_code', 'url', 'sku', 'promotions',
      'properties', 'quantity', 'points', 'points_price', "discount_top"
    ];
  }

  belongsTo() {
    return [
      { 'name': 'product', 'class': Product }
    ];
  }

  getTransactionType() {
    return this.product.transaction_type;
  }

  getUrl() {
    return this.product.getUrl();
  }

  available() {
    return this.quantity > 0;
  }

  setPoints(points: number) {
    this.points = points;
  }
}

//brand/profile

export class BrandProfile extends Base {
  cover: string;
  bio: string;
  avatar: string;
  text: string;
  color: string;

  attributes() {
    return ['cover', 'bio', 'avatar', 'text', 'color'];
  }
}


//categories/cover

export class Cover extends Base {
  normal: string;
  thumb: string;

  attributes() {
    return ['normal', 'thumb']
  }
}

//categories/address

export class Address extends Base {
  id: number;
  first_name: string;
  last_name: string;
  telephone: string;
  doc_type: string;
  doc_number: string;
  address: string;
  address_2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  email: string;
  street_number: string;

  attributes() {
    return ['id', 'first_name', 'doc_type', 'doc_number', 'last_name', 'telephone', 'address', 'address_2', 'city', 'state', 'country', 'zip', 'email', 'street_number']
  }

  fullName() {
    return this.first_name + ' ' + this.last_name;
  }
}

//categories/checkout_item

export class CheckoutItem extends Base {
  currency: {};
  name: string;
  on_sale: boolean;
  price: string;
  product: Product;
  quantity: number;
  sale_price: number | null | any;
  shop: any;
  title: string;
  total: string;
  variant: Variant;
  points: number;

  attributes() {
    return [
      'currency', 'name', 'on_sale', 'price', 'product', 'quantity', 'sale_price', 'shop', 'title',
      'total', 'variant', 'points'
    ];
  }

  belongsTo() {
    return [
      { name: 'variant', class: Variant },
      { name: 'product', class: Product }
    ];
  }

  realPrice() {
    return this.on_sale ? this.salePrice() : this.regularPrice();
  }

  salePrice() {
    return parseFloat(this.sale_price.toString()).toFixed(2);
  }

  regularPrice() {
    return parseFloat(this.price.toString()).toFixed(2);
  }
}

//checkout/promotion

export class Promotion extends Base {
  id: number;
  name: string;
  display_name: string;
  discount: number;
  allow_coupon: boolean;

  attributes() {
    return ['id', 'name', 'display_name', 'discount', 'allow_coupon'];
  }

  parsedDiscount() {
    return parseFloat(this.discount.toString()).toFixed(2);
  }
}

//despegar/offer

export class Offer extends Base {
  id: string;
  href: string;
  name: string;
  image: string;
  continent: string;
  offerType: string;
  offerStatus: string;
  destination: string;
  destiantionType: string;
  destinationCode: string;
  price_detail: {
      currency: string;
      total: number;
  };


  attributes() {
      return [
          'id', 'href', 'name', 'image', 'continent', 'offerStatus',
          'destination', 'destinationType', 'destinationCode', 'price_detail',
          'offerType'
      ];
  }
}

//other.model_no_bines

export class DecidirNoBines extends Base {
  public_key: string;
  endpoint: string;
  installments: string[];

  attributes() {
    return ['public_key', 'endpoint', 'installments'];
  }
}

//gateways/decidir

export class Decidir extends Base {
  public_key: string;
  endpoint: string;

  attributes() {
    return ['public_key', 'endpoint'];
  }
}


//gateways/firstdata

export class FirstData extends Base {
  user: string;
  endpoint: string;
  store_id: string;
  password: string;
  certificate_password: string;

  attributes() {
    return ['user', 'endpoint', 'store_id', 'password', 'certificate_password'];
  }
}


//gateways/mercadopago_decidir

export class MercadopagoDecidir extends Base {
  public_key: string;
  decidir_public_key: string;
  decidir_endpoint: string;

  attributes() {
    return ['public_key', 'decidir_public_key', 'decidir_endpoint'];
  }
}

//gateways/mercadopago_ticket

export class MercadopagoTicket extends Base {
  public_key: string;

  attributes() {
    return ['public_key'];
  }
}

//gateways/mercadopago_key

export class MercadopagoKey extends Base {
  public_key: string;

  attributes() {
    return ['public_key'];
  }
}

//gateways/tarjeta_digital

export class TarjetaDigital extends Base {
  public_key: string;
  endpoint: string;

  attributes() {
    return ['public_key', 'endpoint'];
  }
}

//todopago_decidir

export class TodopagoDecidir extends Base {
  hybrid_js: string;
  endpoint: string;
  decidir_public_key: string;
  decidir_endpoint: string;

  attributes() {
    return ['hybrid_js', 'endpoint', 'decidir_public_key', 'decidir_endpoint'];
  }
}

//gateways/todopago

export class Todopago extends Base {
  hybrid_js: string;
  endpoint: string;

  attributes() {
    return ['hybrid_js', 'endpoint'];
  }
}

//seo/metatag

export class Metatag implements MetaTagInterface {

  title: string;
  description: string;
  url: string;
  image_src: string;

  attributes() {
    return [ 'title', 'description', 'url', 'image_src' ];
  }

}


//account

export class Order extends Base {
  id: number;
  title: string;
  coupon_discount: number;
  total: number;
  status: string;
  get_object_id: number;
  gateway_data: {};
  suborders: Suborder[];
  total_points: number;
  total_without_points: string;
  order_type: string;
  target_item: string;
  created_at: string;
  gateway: string;
  miles: string;

  attributes() {
    return [ 'id', 'title', 'coupon_discount', 'total', 'status',
             'get_object_id', 'gateway_data', 'suborders', 'total_points',
             'total_without_points', 'order_type', 'target_item', 'created_at', 'gateway', 'miles' ];
  }

  hasMany() {
    return [
      { 'name': 'suborders', 'class': Suborder }
    ];
  }

}

export class Suborder extends Base {
  tracking_number: string;
  carrier: string;
  total: number;
  status: string;
  public_id: string;
  created_at: string;
  items: Item[];
  shipments: any[];
  order: Order;
  total_points: number;
  total_without_points: string;

  attributes() {
    return ['status', 'tracking_number', 'carrier', 'items', 'order', 'shipments', 'total', 'created_at', 'public_id', 'total_points', 'total_without_points'];
  }

  belongsTo() {
    return [ { 'name': 'order', 'class': Order } ];
  }

  hasMany() {
    return [ { 'name': 'items', 'class': Item },

     ];
  }

  getCreatedAt(){
    if(this.created_at){
      return new Date(this.created_at);
    }
  }
}

export class Item extends Base {
  quantity: number;
  title: string;
  price: number;
  status: string;
  sku: string;
  url: string;
  picture: Picture;
  transaction_type: string;

  attributes() {
    return ['title', 'quantity', 'price', 'status', 'sku', 'picture', 'url', 'transaction_type'];
  }

  belongsTo() {
    return [ { 'name': 'picture', 'class': Picture } ];
  }
}

export class Customer extends Base {
  id: number;
  dni: string;
  cuil?: number;
  cuit?: string;
  email: string;
  image: string;
  token: string;
  doc_type: string;
  doc_number: string;
  birthday_at: string;
  gender: string;
  points: number;
  password: string;
  last_name: string;
  telephone: string;
  first_name: string;
  gender_name: string;
  password_confirmation: string;
  temporary_email: boolean;
  //Data para usuarios Macro
  client_category?: string;
  client_category_description?: string;
  client_type?: string;
  points_money: number;
  customer_points_enabled: boolean;
  points_error?: IContent;
  site_points_enabled?: boolean;

  attributes() {
    return [
      'id', 'email', 'first_name', 'last_name', 'image',
      'doc_type', 'doc_number', 'password', 'password_confirmation',
      'token', 'telephone', 'birthday_at', 'gender', 'gender_name',
      'points', 'temporary_email', 'client_category', 'client_category_description', 'client_type', 'points_error', 'site_points_enabled', 'cuil', 'cuit'];
  }

  getFullName() {
    return  (
      (this.first_name && this.last_name) ?  this.first_name + ' ' + this.last_name :
      (this.first_name) ? this.first_name :
      (this.last_name) ? this.last_name :
      (this.email) ? this.email : null);
  }

  getEmail() {
    return this.email ? this.email : '';
  }

  setCustomer(data: Data) {
    this.id = data.id ? data.id : null;
    this.cuit = data.cuit ? data.cuit : null
    this.points = data.points ? data.points : 0;
    this.points_money = data.points_money ? data.points_money : 0;
    this.customer_points_enabled = data.customer_points_enabled;
    this.email = data.email ? data.email : null;
    this.image = data.image ? data.image : null;
    this.token = data.token ? data.token : null;
    this.gender = data.gender ? data.gender : 'male';
    this.password = data.password ? data.password : null;
    this.doc_type = data.doc_type ? data.doc_type : 'DNI';
    this.doc_number = data.doc_number ? data.doc_number : 0;
    this.last_name = data.last_name ? data.last_name : null;
    this.telephone = data.telephone ? data.telephone : null;
    this.first_name = data.first_name ? data.first_name : null;
    this.birthday_at = data.birthday_at ? data.birthday_at : new Date(1990, 0, 1);
    this.gender_name = data.gender_name ? data.gender_name : 'masculino';
    this.password_confirmation = data.password_confirmation ? data.password_confirmation : null;
    this.client_category = data.client_category ? data.client_category : null;
    this.client_category_description = data.client_category_description ? data.client_category_description : null;
    this.client_type = data.client_type ? data.client_type : null;
    this.site_points_enabled = data.site_points_enabled;
    this.cuil = data.cuil ? data.cuil : null;
  }

  getDate() {
    return this.birthday_at ? (new Date(this.birthday_at)) : (new Date(1990, 0, 1));
  }

  getCustomerPoints() {
    return this.points;
  }

}

export class Coupon extends Base {
  id: number;
  code: string;
  status?: string;
  amount: number;
  amount_available?: number;
  expires_at?: Date;
  msg?: string;

  attributes() {
    return ['id', 'code', 'status', 'amount', 'amount_available', 'expires_at'];
  }
}

export class BnaUser extends Customer {
  cuil: number;
  idSolicitud: number;
  monto: number;
  sucursal: number;
  programa: string;
  perfil: string;
  fecha: Date;

  attributes() {
      return [
        'cuil', 'idSolicitud', 'monto', 'sucursal', 'programa', 'perfil', 'fecha']
    }

  setBnaUser(data: Data) {
      this.cuil = data.cuil ? data.cuil : 0;
      this.monto = data.monto ? data.monto : 0;
      this.email = data.email ? data.email : null;
      this.perfil = data.perfil ? data.perfil : null;
      this.programa = data.programa ? data.programa : 0;
      this.sucursal = data.Sucursal ? data.Sucursal : null;
      this.fecha = data.fecha ? data.fecha : '';
      this.first_name = data.nombre ? data.nombre : 'Tienda';
      this.last_name = data.apellido ? data.apellido : 'BNA';
      this.idSolicitud = data.idSolicitud ? data.idSolicitud : 0;
  }

  getMaxAmount() {
      return (this.monto ? this.monto : 0);
  }

  getCuil() {
      return this.cuil ? this.cuil : 0;
  }

  getSucursal() {
      return this.sucursal ? this.sucursal : 0;
  }

  getIdSolicitud() {
      return this.idSolicitud ? this.idSolicitud : 0;
  }

  getPerfil() {
      return this.perfil ? this.perfil : '';
  }

  getPrograma() {
      return this.programa ? this.programa : '';
  }

  getFecha() {
      return this.fecha ? this.fecha : '';
  }

}

export class MacroUser extends Customer {
  success: boolean;
  valid_until: string;
  cobis: string;
  doc_number: string;
  temporary_email: boolean;
  is_select_user: boolean;
  user_info: any

  attributes() {
      return [
        'success', 'valid_until', 'cobis', 'temporary_email', 'doc_number', 'is_select_user']
    }

  setMacroUser(data: Data) {
      this.token = data.token ? data.token : null;
      this.success = data.success ? data.success : false;
      this.valid_until = data.valid_until ? data.valid_until : null;
      this.cobis = data.user_info.client_code ? data.user_info.client_code : 0;
      this.last_name = data.user_info.last_name ? data.user_info.last_name : 'Macro';
      this.first_name = data.user_info.first_name ? data.user_info.first_name : 'Usuario';
      this.doc_number = data.user_info.doc_number ? data.user_info.doc_number : '';
      this.temporary_email = data.user_info.temporary_email ? data.user_info.temporary_email : false
      this.points = data.user_info.available_visa_points ? data.user_info.available_visa_points : 0;
      this.is_select_user = data.user_info.is_select_user ? data.user_info.is_select_user : false;
  }

}

export class TechnisysUser extends Customer {
  success: boolean;

  setTechnisysUser(data: Data) {
      this.token = data.token ? data.token : null;
      this.success = data.success ? data.success : false;
      this.last_name = data.user_info.last_name ? data.user_info.last_name : 'Technisys';
      this.first_name = data.user_info.first_name ? data.user_info.first_name : 'Usuario';
      this.setCustomer(data);
  }

}
