import { Injectable } from '@angular/core';
import { QualtricsService } from './qualtrics.service';

@Injectable({
    providedIn: 'root'
})
export class QualtricsDataService  {

  recargasText: string = "";
  cantidadRecargas: number;

  constructor(private _qualtricsService: QualtricsService){}

    setUserData(userInfo: any) {
        window.sessionStorage.setItem("user.entityMis", userInfo.client_code);
        window.sessionStorage.setItem("user.nombre", userInfo.first_name.trim().toUpperCase());
        window.sessionStorage.setItem("user.apellido", userInfo.last_name.trim().toUpperCase());
        window.sessionStorage.setItem("user.codTipoPersona", userInfo.client_type);
        window.sessionStorage.setItem("user.codCategoria", userInfo.client_category);
        // VALOR POR DEFAULT 0
        window.sessionStorage.setItem("user.cierreSesion", "0");
        // VALOR POR DEFAULT 1, INACTIVIDAD, VALOR 0 ES CIERRE DE SESION MANUAL
        window.sessionStorage.setItem("user.tipoCierre", "1");
        window.sessionStorage.setItem("user.transactions", "");

        // setTimeout(() => {
        //   this.setFirstScript();
        // }, 1000);
    }

    cleanUserData() {
        window.sessionStorage.removeItem("user.entityMis");
        window.sessionStorage.removeItem("user.nombre");
        window.sessionStorage.removeItem("user.apellido");
        window.sessionStorage.removeItem("user.codTipoPersona");
        window.sessionStorage.removeItem("user.codCategoria");
        window.sessionStorage.removeItem("user.cierreSesion");
        window.sessionStorage.removeItem("user.tipoCierre");
        window.sessionStorage.removeItem("user.transactions");
    }

    concatRecarga(recarga: string){
      this.recargasText = window.sessionStorage.getItem("user.transactions");
      this.cantidadRecargas = this.recargasText ? this.recargasText?.split(",").length : 0;

      if( this.cantidadRecargas < 10){
        if (this.recargasText === ""){
          window.sessionStorage.setItem("user.transactions", recarga);
        }else{
          this.recargasText = this.recargasText ? this.recargasText?.concat(",", recarga) : "";
          window.sessionStorage.setItem("user.transactions", this.recargasText);
        }
      }
    }

    setFirstScript(){
      const script = document.createElement('script');
      script.type = 'text/javascript';

      script.text = `
      if(typeof QSI === "undefined") {
        console.log(typeof QSI);
        QSI = {};
        QSI.config = {

            externalReference: ${sessionStorage.getItem("user.entityMis")},
            firstName: "${sessionStorage.getItem("user.nombre")}",
            lastName: "${sessionStorage.getItem("user.apellido")}"

        };
      }else {
        QSI.config = {
          externalReference: ${sessionStorage.getItem("user.entityMis")},
          firstName: "${sessionStorage.getItem("user.nombre")}",
          lastName: "${sessionStorage.getItem("user.apellido")}"
        };
      };`

      document.head.prepend(script);

      //this._qualtricsService.setQualtricsScriptTag();

    }
}
