export const environment = {
  production: true,
  /* API */
  token: 'aWCGv0LSkMI9v_mZB2jDxw',
  endpoint: 'https://api-macro.avenida.com/api/angular_app',
  session:
    '5d70e59ca26152656231e4f8ee57d9702b68e37a6e3ee3e9c7d2859c0295e9bca476edfe90bbe99ec838aff7952ff7ae63997a0deb32a55207df607117d24cc0',
  /* Site */
  site_title: 'Macro Premia',
  name: 'Macro',
  domain: 'https://macropremia.com.ar',
  friendly_domain: 'macropremia.com.ar',
  email: null,
  phone: '0810 555 2355',
  whatsapp: null,
  gateway: 'decidir',
  cft: null,
  cftna: '0,00%',
  tna: '0.00%',
  checkout_image: null,
  cards: true,
  /* Promos */
  cybermonday: false,
  hotsale: false,
  /* Transaction */
  cart: false,
  oneClick: true,
  installments: 24,
  taxAddress: false,
  google_conversion_id: '',
  google_conversion_label: '',
  google_remarketing_only: false,
  facebook_app_id: null,
  installments_without_interest: false,
  dataFiscal: 'http://qr.afip.gob.ar/?qr=z3nQ4Eh8wqt68YM2w_effA,,',
  regret_buy: '/devoluciones',
  consumer_defense:
    'https://www.argentina.gob.ar/produccion/defensadelconsumidor/formulario',
  /* Account */
  login: true,
  external_login: true,
  register_form: false,
  checkoutUserValidation: false, // Key de config para activar la validacion de Renaper y Equifax al momento de realizar la compra
  recovery_password: false,
  reviews: false,
  /* Social */
  social: false,
  instagram: null,
  facebook: null,
  twitter: null,
  /* Layout */
  header_version: 0,
  footer: 0,
  variantCard: 0,
  buyBox: 0,
  checkout_version: 0,
  sizeChart: false,
  imagePreview: false,
  genericCheckoutError: false,
  showRealtedProducts: true,
  /* Geolocation */
  geolocation: false,
  recaptcha_key: '',
  /* Pre load localities and Zip-codes */
  zendesk_key: '20bccc5a-4ad4-4ca9-92cb-4ca43525b9b6',
  emblue_code: '',
  loadZipcodes: true,
  gtm_id: ['GTM-PZ3WKVS'],
  zoho_url: '',
  facebook_pixel_id: '1662821737236931',
  points_enabled: false, // enable/disable spend points on store
  ENCRYPT_KEY: '',
  loanAvailable: true,
};
