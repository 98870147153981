import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'price-without-tax',
  template: `
    <div *ngIf="price && price > 0" class="pb-2" [ngClass]="customClass">
      <small
        class="without-tax"
        [ngClass]="{
          'variant-size': isVariantCard
        }">
        *Precio sin Impuestos Nacionales
        {{ price | currency : 'ARS' : 'symbol' : '1.2-2' : 'es-AR' }}
      </small>
    </div>
  `,
  styles: [
    `
      .without-tax {
        color: #8b8b8b;
        font-size: 14px;
        font-weight: 400;
      }

      .variant-size {
        font-size: 9px;
        font-weight: 400;
        line-height: 0.5;
        margin-bottom: 10px;
      }
    `
  ]
})
export class PriceWithoutTaxComponent implements OnInit, OnDestroy, OnChanges {
  @Input() regularPrice: number;
  @Input() salePrice: number;
  @Input() isVariantCard: boolean = false;
  @Input() customClass: string = '';

  price: number = 0;

  ngOnDestroy(): void {
    this.price = 0;
  }

  ngOnInit(): void {
    this.price = this.salePrice
      ? Number(this.salePrice)
      : Number(this.regularPrice);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const regular = changes.regularPrice?.currentValue;
    const sale = changes.salePrice?.currentValue;
    this.price = sale ? Number(sale) : Number(regular);
  }
}
