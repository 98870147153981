import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Params, Data } from '@angular/router';
import { environment } from '../environments/environment';
import { LoaderService } from './loader.service';
import { map, finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LocalStorage } from './local_storage.service';

@Injectable()

export class HttpService {
  private headers: HttpHeaders;

  constructor(private http: HttpClient,
              private loaderService: LoaderService,
              private localstorage: LocalStorage
            ) {
            this.getHeaders();
          }

  getHeaders() {
    const auth_token = this.localstorage.get('auth_token');
    const headerObject = {
      'Content-Type': 'application/json',
      'Api-Key': environment.token,
    };
    if (auth_token) {
      headerObject['auth-token'] =  auth_token.toString();
    }
    this.headers = new HttpHeaders(headerObject);
  }

  get(url: string, queryParams?: Params, json?: boolean): Observable<Data> {
    this.loaderService.show();
    this.getHeaders();
    const headers: HttpHeaders = this.headers;
    const request = this.buildRequest(url, queryParams, json);
    const httpGet$ = this.http.get<any>(environment.endpoint + '/' + request, {headers})
    .pipe(
      map(
        (response) => {
          this.loaderService.hide();
          return response;
        })
    ).pipe(
      finalize(
        () => {
          this.loaderService.hide();
        }
      )
    );

    return httpGet$;
  }

  getSimple(url: string, params?: HttpParams): Observable<Data> {
    this.loaderService.show();
    this.getHeaders();
    const headers: HttpHeaders = this.headers;
    const httpGet$ = this.http.get<any>(`${environment.endpoint}/${url}`, {headers, params})
    .pipe(
      map(
        (response) => {
          this.loaderService.hide();
          return response;
        })
    ).pipe(
      finalize(
        () => {
          this.loaderService.hide();
        }
      )
    );

    return httpGet$;
  }

  getSimpleWithBody(url: string, body?: any): Observable<Data> {
    console.log('body', body)
    this.getHeaders();
    const headers: HttpHeaders = this.headers;
    
    const options = {
      headers,
      body: JSON.stringify(body)
    };
  
    const httpGet$ = this.http.get<any>(`${environment.endpoint}/${url}`, options)
      .pipe(
        map((response) => {
          return response;
        }),
      );
  
    return httpGet$;
  }

  post(url: string, body: any, options?: any): Observable<Data> {
    this.loaderService.show();
    return this.basePost(url, body, options).pipe(
      map(
      (response) => {
        return response;
      }
      )
    ).pipe(finalize(
      () => {
        this.loaderService.hide();
      })
    );
  }

  patch(url: string, body: any): Observable<Data> {
    this.loaderService.show();

    this.getHeaders();
    const headers: HttpHeaders = this.headers;
    return this.http.patch(environment.endpoint + '/' + url, body, {headers}).pipe(
      map(
        (response: Response) => {
        return response;
        }
      )
    ).pipe(
      finalize(
        () => {
          this.loaderService.hide();
      })
    );
  }


  // Nuevo Método PUT
  put(url: string, body: any): Observable<Data> {
    this.loaderService.show();

    this.getHeaders();
    const headers: HttpHeaders = this.headers;
    return this.http.put(environment.endpoint + '/' + url, body, {headers}).pipe(
      map(
        (response: Response) => {
        return response;
        }
      )
    ).pipe(
      finalize(
        () => {
          this.loaderService.hide();
      })
    );
  }

  delete(url: string, body: any): Observable<Data> {
    this.loaderService.show();
    this.getHeaders();
    const options: any = { headers: this.headers };
          options.body = body;
    return this.http.delete(environment.endpoint + '/' + url, options).pipe(
      finalize(
        () => {
          this.loaderService.hide();
      })
    );
  }


  basePost(url: string, body: any, requestOptions?: any) {
    this.getHeaders();
    const options: any = { headers : this.headers }
    options.body = body;
    options.params = requestOptions?.params;
    return this.http.post(environment.endpoint + '/' + url + '.json', body, options)
  }

  externalPost(url: string, body: any, headers) {
    this.loaderService.show();
    const httpPost$ = this.http.post<any>(url, body, {headers})
    .pipe(
      map(
        (response) => {
          this.loaderService.hide();
          return response;
        })
    ).pipe(
      finalize(
        () => {
          this.loaderService.hide();
        }
      )
    );
    return httpPost$;
  }

  addHeader(headerName: string, body: any) {
    this.removeHeader(headerName);
    this.headers.append(headerName, body);
  }

  removeHeader(key: string) {
    this.headers.delete(key);
  }

  buildRequest(request: string, queryParams?: Params, json?: boolean) {
    if (!queryParams) {
      return request + (json? '.json?' :  '?')
    }

    const params = [];

    for (const key in queryParams) {
      if (Array.isArray(queryParams[key])) {
        queryParams[key].forEach((item, index) => {
          params.push(key + '=' + item);
        })
      } else {
        params.push(key + '=' + queryParams[key])
      }
    }

    return request + (json? '.json?' :  '?') + params.join("&");
  }
}
