import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BnaChatbotService {

  constructor() { }

  addChatbotScript() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://nacionservicios.s1gateway.com/pe/41c69GWtNFJk`

    document.body.append(script);
  }
}
