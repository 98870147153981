import { QualtricsDataService } from './../services/third-party/qualtrics-data.service';
import { Component, OnDestroy, OnInit, ElementRef, ViewChild, Inject, PLATFORM_ID } from '@angular/core';
import { Subscription } from "rxjs";
import { LoaderState } from '../interfaces/loader_state.interface';
import { LoaderService } from '../services/loader.service';
import { NavigationEnd, Router } from '@angular/router';
import { GtmService } from '../services/gtm.service';
import { MatDialog } from "@angular/material/dialog";
import { NewsletterComponent } from './dialogs/newsletter/newsletter.component';
import { LocalStorage } from "../services/local_storage.service";
import { StoreService } from "../services/store.service";
import { UserIdleService } from '../services/user-inactivity.service';
import { AuthenticationService } from '../services/authentication.service';
import { GenerateEnvTagsService } from './env-tags/generate-env-tags.service';
import { Store } from "../models/models.model";
import { Title } from '@angular/platform-browser';
import { filter, distinctUntilChanged, tap, first } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { RelationService } from "../services/model.service";
import { environment } from '../environments/environment';
import { ModoService } from './new-core/core/services/modo.service';
import { QualtricsService } from '../services/third-party/qualtrics.service';
import { CATEGORIA_CLIENTE } from './mocks/macro-cliente-categoria';
import { RangeValueAccessor } from '@angular/forms';
import { CrazzyEggService } from './new-core/core/services/scripts/crazy_egg/crazy-egg-tag.service';
import { UserSessionService } from '../services/user-session.service';
import { MetaService } from './new-core/core/services/meta.service';
import { BnaChatbotService } from './new-core/core/services/bna-chatbot.service';

declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  show = 0;
  store: Store;
  storeName: string;
  loaderServiceObs: Subscription;
  title = environment.site_title;
  @ViewChild('categories', { static: false }) categories: ElementRef;

  closingAttempts = 0;
  checkoutImage = environment.checkout_image;

  isBnaXml = false;

  constructor(private loaderService: LoaderService,
              private router: Router,
              private gtmService: GtmService,
              private dialog: MatDialog,
              private localStorage: LocalStorage,
              private titleService: Title,
              private userIdleService:UserIdleService,
              private storeService: StoreService,
              private relationService: RelationService,
              private authenticationService: AuthenticationService,
              private generateEnvTagsService: GenerateEnvTagsService,
              private _pagoModoService: ModoService,
              private _qualtricsService: QualtricsService,
              private _crazyEggService: CrazzyEggService,
              private _userSessionService: UserSessionService,
              private _qualtricsDataService: QualtricsDataService,
              @Inject(PLATFORM_ID) private platformId: Object,
              private _metaService: MetaService,
              private _bnaChatbotService: BnaChatbotService,
            ) {
    this.storeService.currentStore().subscribe(
      res => {
        this.store = res;
        this.resolveNewsletter();
      });

  }

  ngOnInit() {
    this.generateEnvTagsService.setTags(environment.name);
    this.titleService.setTitle(this.title);
    this.storeName = environment.name;

    if (this.authenticationService.isLoggedIn()) this.setMacroSession(this.storeName);

    this.loaderServiceObs =
      this.loaderService.get()
      .pipe(
        tap((state: LoaderState) => {
          if (state.show) {
            this.show++;
          } else {
            this.show--;
          }
        })
      ).subscribe();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      tap((r: NavigationEnd) => r.url === '/consulta/feed/emblue' ? this.isBnaXml = true : this.isBnaXml = false),
      filter((event: NavigationEnd) => this.validateOnSiteUrl(event.url) && this.validateBnaEnv(this.storeName)),
      tap(() => this.onSiteScriptConf())
    ).subscribe();

    this.router.events
      .pipe(
        distinctUntilChanged(
          (previous: any, current: any) => {
            if (current instanceof NavigationEnd) { return previous.url === current.url }
            return true;
        }))
      .subscribe(
        (event: NavigationEnd) => {
          if (event instanceof NavigationEnd && isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
          }
          if (this.categories) {
            const categoriesEl = this.categories.nativeElement;
            if (categoriesEl !== null) { categoriesEl.classList.remove('active') }
          }
          if (isPlatformBrowser(this.platformId)) { this.gtmService.trigger('app.pageview', { 'appUrl': event.urlAfterRedirects });
      };
    });

    if (environment.name === 'Macro') {
      this._qualtricsService.setQualtricsScriptTag();

      setTimeout(() => {
        if(this.authenticationService.isLoggedIn()) this._qualtricsDataService.setFirstScript();
      }, 1000);

    };
    if (environment.name === "BancoNacion") {
      this._crazyEggService.addScript();
      this._metaService.addMetaScript();
      // por el momento se deshabilita el chatbot del BNA
      // this._bnaChatbotService.addChatbotScript();
    };
  }

  ngOnDestroy() {
    if (this.loaderServiceObs) this.loaderServiceObs.unsubscribe();
  }

  resolveNewsletter() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.store.popup_image && screen.width >= 768) {
        if(!this.localStorage.get('newsletter_fired')) this.newsPopup();
      }
    }

    this.storeService.gateway().subscribe(
      (response) => {
        this.localStorage.set('gateway', response.gateway);
        if( (response.gateway).includes('modo')){
          this._pagoModoService.setMostrarPagoModo(true);
        }

    }, (error) => {
      console.log(error);
    });

  }

  newsPopup() {
    this.dialog.open(NewsletterComponent,  {
      panelClass: 'newsletter-dialog-container',
      width: '790px',
      height: '510px'
    });
    this.localStorage.set('newsletter_fired', true);
  }

  onSiteScriptConf() {
    if (environment.emblue_code !== '') {
      const onSiteUrl = 'https://widgets-static.embluemail.com/accounts/595161771DFBBEE5A/scripts/sw_5951.js?ts='
      const u =  onSiteUrl + this.getFullDate();
      const onSiteScript = document.createElement('script');
      onSiteScript.async = true;
      onSiteScript.src = u;
      let domRef = document.getElementsByTagName('script')[document.getElementsByTagName('script').length - 1];
      if (domRef.src.includes(onSiteUrl)) {
        domRef.remove();
        domRef = document.getElementsByTagName('script')[document.getElementsByTagName('script').length - 1];
      }

      domRef.parentNode?.insertBefore(onSiteScript, domRef.nextSibling)
    }

  }

  getFullDate(): string {
    const m = new Date();
    return m.getFullYear() + '' + (m.getMonth() + 1) + '' + m.getDate() + 'T' + m.getHours() + '' + m.getMinutes() + '' + m.getSeconds()
  }

  validateOnSiteUrl(url: string): boolean {
    let availablePaths = ['/', '/checkout', '/envio-gratis-todo-elpais', 'oportunidades'];
    return ((availablePaths.filter(path => path === url)).length > 0);
  }

  validateBnaEnv(envName: string): boolean {
    let availableNames = ['BancoNacion', 'EspecialesBNA', 'MiMoto'];
    return ((availableNames.filter(name => name === envName)).length > 0);
  }

  setMacroSession(storeName: String) {
    if (storeName === 'Macro') {
      this.userIdleService.initilizeSessionTimeout();
      this.userIdleService.userIdlenessChecker
        .pipe(
          filter(status => status === 'STOPPED_TIMER'),
          tap(stopped => {
            this.router.navigate(['/session-expired']);
            console.log('Ya se navego desde app');
          })
        )
        .subscribe();
    }
  }
}
