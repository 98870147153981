<div class="banners horizontal hero-component">
  <swiper
    [spaceBetween]="1"
    [centeredSlides]="true"
    [autoplay]="{
          delay: 3000,
          disableOnInteraction: false
          }"
    [slidesPerView]="1"
    [slidesPerGroup]="1"
    [loop]="true"
    [resizeObserver]="true"
    [navigation]="!mobile"
    class=""
  >

    <ng-container *ngFor="let slide of slides" id="banner-left-wrapper">

      <ng-template swiperSlide class="swiperImg">
        <a *ngIf="slide?.image || slide?.mobile_image" (click)="navigate(slide)">
          <img class="coverImg"
               [src]="!mobile ? slide.image : (slide?.mobile_image ? slide.mobile_image : slide.image)"
               alt="covering hero_banner"
               loading="lazy"
          />
        </a>
      </ng-template>

    </ng-container>
  </swiper>

  <div class="separator"></div>

  <div class="banners vertical">
    <app-image [item]="component.data['banners'][0]" appRatio="620:266"></app-image>
    <div class="separator"></div>
    <app-image [item]="component.data['banners'][1]" appRatio="620:266"></app-image>
  </div>
</div>
