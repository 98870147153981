<swiper
  [spaceBetween]="1"
  [centeredSlides]="true"
  [autoplay]="{
        delay: 12000,
        disableOnInteraction: false
        }"
  [slidesPerView]="1"
  [slidesPerGroup]="1"
  [loop]="true"
  [resizeObserver]="true"
  [navigation]="!mobile"
>
  <ng-container *ngFor="let banner of banners">
    <ng-template swiperSlide class="swiperImg">
      <a *ngIf="banner?.image || banner?.mobile_image" (click)="navigate(banner)">
        <img class="coverImg"
             [src]="!mobile ? banner.image : (banner?.mobile_image ? banner.mobile_image : banner.image)"
             alt="covering hero" />
      </a>
    </ng-template>
  </ng-container>
</swiper>
