import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { VariantCardComponent } from "./variant-card.component";
import { CoreModule } from '../../core/core.module';
import { FloatParseModule } from "../../pipes/float-parse.module";
import { BuildInstallmentsTextModule } from "../../pipes/build-installments-text.module";
import { BuildInstallmentsModule } from "../../pipes/build-installments.module";
import {
  BuildInstallmentsTextProductModule
} from "../../pipes/build-installments-text-product.module";
import {
  PriceWithoutTaxModule
} from "../price-without-tax/price-without-tax.module";

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    RouterModule,
    FloatParseModule,
    BuildInstallmentsTextModule,
    BuildInstallmentsModule,
    BuildInstallmentsTextProductModule,
    PriceWithoutTaxModule
  ],
  exports: [VariantCardComponent],
  declarations: [VariantCardComponent]
})
export class VariantCardModule {}
