<div class="gray-color override-with-white-color" id="sube-component">
  <main>
    <div class="container pt-4 pb-5">

      <div class="row">
        <div class="col-md-12">
          <h3 [ngClass]="env.name === 'Macro' ? 'title-macro' : ''">Carga de tarjeta SUBE</h3>
          <hr>
        </div>
      </div>

      <div class="row">

        <div class="col-md-7">
          <div id="promo-recarga-sube">
            <img class="img-fluid mx-auto d-block mb-3" src="/assets/stores/current_store/images/sube.png" alt="sube" />
          </div>
        </div>

        <div class="col-md-5">

          <div id="checkout-sube">

              <mat-horizontal-stepper linear='true' #subeStepper>

                <!-- Instructions / First Step-->
                <mat-step>

                  <h3 class="mb-3" [ngClass]="env.name === 'Macro' ? 'title-macro' : ''">¿Cómo cargar la tarjeta?</h3>
                  <!--<div *ngIf="!isLoggedIn" class="alert alert-danger" role="alert">
                    Inicia sesión para obtener este beneficio.
                  </div>-->

                  <!-- Instruction Steps -->
                  <div class="steps my-4" id="sube-steps" [ngClass]="env.name === 'Macro' ? 'sub-title-macro' : ''">

                    <div class="media mb-3">
                      <div class="step">1.</div>
                      <div class="media-body">
                        <p>Ingresá tu número de tarjeta SUBE.</p>
                      </div>
                    </div>

                    <div class="media mb-3">
                      <div class="step">2.</div>
                      <div class="media-body">
                        <p>Seleccioná el monto a cargar y canjeá tus Puntos.</p>
                      </div>
                    </div>

                    <div class="media mb-3">
                      <div class="step">3.</div>
                      <div class="media-body">
                        <p>Podés acreditar tu carga en una Terminal Automática, en  el colectivo a través de Carga a Bordo o con la app SUBE. Más  Información en: <a href="https://www.argentina.gob.ar/sube" target="_blank">https://www.argentina.gob.ar/sube</a></p>
                      </div>
                    </div>

                  </div>

                  <!-- SUBE Points / Value -->
                  <div class="sube-values mb-1 my-3" id="sube-values" *ngIf="showPoints">
                    <ul>
                      <li *ngFor="let item of promotions" class="mb-2">
                        <span class="sube_amount">
                          <p class="amount_number">${{ item.amount | floatparse }}</p>
                          <p class="points-text">{{ math.ceil(item.amount / subeEquivalence) | floatparse }} Puntos</p>
                        </span>
                      </li>
                    </ul>
                  </div>

                  <!-- buttons -->
                  <a class="btn btn-sm btn-link btn-block" target="_blank" href="https://tarjetasube.sube.gob.ar/subeweb/WebForms/admin/views/mapa-sube.aspx?id=5">VER TERMINALES CERCANAS</a>
                  <button class="btn btn-lg btn-primary btn-block" (click)="hasPoints(subeStepper)">Continuar</button>

                </mat-step>


                <!-- SUBE card data / Second Step-->
                <mat-step [stepControl]="selectAmountGroup">

                  <h3 class="mb-3" [ngClass]="env.name === 'Macro' ? 'title-macro' : ''">Para cargar la tarjeta</h3>

                  <!-- Error Feedback -->
                  <div *ngIf="formInvalid" class="alert alert-danger mt-3 mb-4" role="alert">
                    <h6 class="alert-heading">Datos incorrectos</h6>
                    <small>Verifica los datos ingresados e intentalo nuevamente.</small>
                  </div>

                  <form [formGroup]="selectAmountGroup">

                    <!-- Buttons Admin SUBE data -->
                    <div class="row mb-2" [ngClass]="env.name === 'Macro' ? 'sub-title-macro' : ''">

                      <div class="col-md-7">
                        <!-- Input Tarjeta SUBE -->
                        <mat-form-field appearance="outline" class="full-width">
                          <mat-label>Ingresá el número de tu tarjeta SUBE</mat-label>
                          <input  [dropSpecialCharacters]="true" mask="0000 0000 0000 0000"
                                  type="text" matInput formControlName="confirmCard"
                                  placeholder="Ej. 2453 4566 5555 5555"
                                  required>
                        </mat-form-field>
                        <p *ngIf="!validCard" class="pl-2 mb-3 ml-0 text-danger"><small><b>El número de tarjeta es incorrecto</b></small></p>
                      </div>

                      <div class="col-md-5 mt-1 mb-3 px-md-0" id="sube-action-buttons">
                        <button class="btn btn-sm btn-primary mr-1 sube-buttons" (click)="openSelectCard('select')"><i class="fa fa-search"></i></button>
                        <button class="btn btn-sm btn-primary mr-1 sube-buttons" (click)="openNewCard()"><i class="fa fa-plus"></i></button>
                        <button class="btn btn-sm btn-primary mr-1 sube-buttons" (click)="openEditCard()"><i class="fa fa-edit"></i></button>
                        <button class="btn btn-sm btn-primary mr-1 sube-buttons" (click)="openSelectCard('delete')"><i class="fa fa-trash"></i></button>
                      </div>
                    </div>

                    <!-- Input Email -->
                    <mat-form-field appearance="outline" class="full-width">
                      <mat-label>Completá el campo con tu e-mail</mat-label>
                      <input type="email" matInput formControlName="confirmEmail" placeholder="usuario@dominio.com" required>
                    </mat-form-field>

                    <!-- Input Amount -->
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Seleccioná el monto a cargar</mat-label>
                        <mat-select placeholder="Selecciona el monto" formControlName="selectAmount" required>
                          <mat-option disabled>Selecciona el monto</mat-option>
                          <mat-option *ngFor="let value of promotions" [value]="value.amount" [disabled]="esCanjeable(value.points)">
                            <b>${{ value.amount | floatparse }}</b> = {{ math.ceil(value.amount / subeEquivalence) | floatparse }} Puntos
                          </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!-- Buttons -->
                    <button class="btn btn-lg btn-primary btn-block" (click)="validateTransaction(subeStepper)">Confirmar</button>
                    <button class="btn btn-sm btn-link btn-block" matStepperPrevious>Volver</button>

                  </form>
                </mat-step>

                <!-- Confirm Data / Third Step-->
                <mat-step>

                  <h3 class="mb-3" [ngClass]="env.name === 'Macro' ? 'title-macro' : ''">Confirmar Carga</h3>

                  <div id="confirmarDatos" [ngClass]="env.name === 'Macro' ? 'sub-title-macro' : ''">

                    <div class="col-12 py-4">
                      <h5>Tarjeta SUBE</h5>
                      <p>{{ cardNumber | mask: '0000 0000 0000 0000'}}</p>

                      <h5>Monto a cargar</h5>
                      <p>${{ montoCarga | floatparse }}</p>

                      <h5>Puntos Macro Premia</h5>
                      <p>{{ math.ceil(pointsEquivalence) | floatparse }} Puntos</p>
                    </div>

                    <button class="btn btn-lg btn-primary btn-block" (click)="acreditSube(subeStepper)">Confirmar Carga</button>
                    <button class="btn btn-sm btn-link btn-block" matStepperPrevious>Volver</button>
                  </div>

                </mat-step>


                <!-- Process data / Last Step-->
                <mat-step>

                  <div class="procesando" *ngIf="processData">
                      <div class="col-12 py-4 px-4 text-center">
                        <h4 class="my-2">Su operación está<br>siendo procesada</h4>
                        <h6 class="mx-auto mb-3"></h6>
                        <mat-spinner class="mx-auto my-5"></mat-spinner>
                      </div>
                  </div>

                  <div *ngIf="success && !processData">
                      <div class="col-12 p-4 mb-3 text-center procesando">
                        <h4 class="my-2" [ngClass]="env.name === 'Macro' ? 'title-macro' : ''">Transacción exitosa</h4>
                        <h6 class="mx-auto mb-3">En los próximos minutos recibirás la confirmación en tu correo</h6>

                        <table class="table table-sm text-left" [ngClass]="env.name === 'Macro' ? 'sub-title-macro' : ''">
                            <tbody>
                              <tr>
                                <th>Tarjeta SUBE:</th>
                                <td>{{ cardNumber | mask: '0000 0000 0000 0000'}}</td>
                              </tr>
                              <tr>
                                <th>E-mail:</th>
                                <td>{{ getEmail() }}</td>
                              </tr>
                              <tr>
                                <th>Monto cargado:</th>
                                <td>${{ montoCarga | floatparse }}</td>
                              </tr>
                              <tr>
                                <th>Código de operación:</th>
                                <td>{{ orderId }}</td>
                              </tr>
                            </tbody>
                          </table>

                      </div>
                      <!-- Button -->
                      <a class="btn btn-lg btn-primary btn-block mb-2" [routerLink]="['/']">CONTINUAR NAVEGANDO</a>
                      <div class="col-12 py-2 px-4 text-left">
                        <p class="mb-3"><a class="btn-link" href="https://tarjetasube.sube.gob.ar/subeweb/WebForms/admin/views/mapa-sube.aspx?id=5" target="_blank"><small><b>VER TERMINALES CERCANAS</b></small></a></p>
                        <p class="mb-3"><small>No te olvides de <b>registrar tu SUBE</b> para ver movimientos, descuentos y recibir información sobre el transporte público</small></p>
                      </div>
                  </div>

                  <div *ngIf="!success && !processData">
                      <div class="col-12 py-4 px-4 mb-3 text-center procesando">
                        <h4 class="mx-auto py-4">{{error}}</h4>
                      </div>

                      <!-- Button -->
                      <button class="btn btn-lg btn-primary btn-block" matStepperPrevious>Reintentar</button>
                      <a class="btn btn-sm btn-link btn-block" href="https://tarjetasube.sube.gob.ar/subeweb/WebForms/admin/views/mapa-sube.aspx?id=5" target="_blank">VER TERMINALES CERCANAS</a>
                  </div>

                </mat-step>
              </mat-horizontal-stepper>

          </div>

        </div>

      </div> <!-- End Row -->

      <!-- Legal Row -->
      <div class="mt-3">
        <p class="text-justify format-p">
          <small class="text-muted">
            Puntos requeridos para cada monto de carga SUBE detallados en la web. Para realizar una solicitud de puntos por carga SUBE, se deberá ingresar el número de tarjeta SUBE que se desee cargar y el mail.  Podés acreditar tu carga en una Terminal Automática, en el colectivo a través de Carga a Bordo o con la app SUBE. Más información en: https://www.argentina.gob.ar/sube. El servicio no tiene costo adicional. La carga sólo se acreditará cuando el resultado de su sumatoria con el saldo actual en la tarjeta SUBE no supere los $40.000. La vigencia del saldo luego de la acreditación por medio de una terminal automática se rige bajo las condiciones definidas por Nación Servicios S.A. CUIT: 33-62136455-9 Domicilio: Santa Fe 1345, Rosario, Santa Fe. La solicitud de este premio no admite cambios ni devolución de puntos. Consulte ubicación de las terminales automáticas en www.argentina.gob.ar/sube Consulte más información, en macro.com.ar, llamando al 0810-555-2355 o en la Sucursal más cercana a su domicilio.
          </small>
        </p>
      </div>
      <!-- End Legal Row -->

    </div>
  </main>
</div>
