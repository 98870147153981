import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceWithoutTaxComponent } from './price-without-tax.component';
// import currency
import { registerLocaleData } from '@angular/common';
import localeEsAR from '@angular/common/locales/es-AR';

registerLocaleData(localeEsAR, 'es-AR');

@NgModule({
  declarations: [PriceWithoutTaxComponent],
  imports: [CommonModule],
  exports: [PriceWithoutTaxComponent],
  providers: [[{ provide: LOCALE_ID, useValue: 'es-AR' }]]
})
export class PriceWithoutTaxModule {}
